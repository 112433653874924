import React, {useState} from 'react';
import {withStyles} from "@mui/styles";
import {useIntl} from "react-intl";
import {Tab, Typography} from "@material-ui/core";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {History} from "./components/History";
import {Button} from "../../components";
import ImportDialog from "../../components/ImportDialog/ImportDialog";

const styles = {
  pointsBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  },
  pointsHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem'
  }
}

const Participants = ({ ...props }) => {
  const intl = useIntl();

  const LIST_TAB = { label: intl.formatMessage({id: 'spider.participants.list'}), index: '1', url: 'list' }
  const HISTORY_TAB = { label: intl.formatMessage({id: 'spider.participants.history'}), index: '2', url: 'history' }

  const [tabValue, setTabValue] = useState(HISTORY_TAB.index);
  const [openImport, setOpenImport] = useState(false);

  const handleChange = (event, tab) => {
    setTabValue(tab);
  };

  const importPoints = () => {
    setOpenImport(true)
  };

  return (
      <div className={props.classes.pointsBody}>
        <div className={props.classes.pointsHeader}>
          <Typography variant={'h1'} component={'h1'} className={'underline-left'}>
            {intl.formatMessage({id: 'spider.participants.title'})}
          </Typography>
          <Button color={'primary'} variant={'outlined'} size={'large'} onClick={importPoints}>
            {intl.formatMessage({id: 'spider.participants.import'})}
          </Button>
        </div>

        <TabContext value={tabValue}>
          <div className={'tabs-container'}>
            <TabList onChange={handleChange} disableRipple>
              <Tab label={LIST_TAB.label} value={LIST_TAB.index} disableFocusRipple/>
              <Tab label={HISTORY_TAB.label} value={HISTORY_TAB.index} disableFocusRipple/>
            </TabList>
            <TabPanel value={LIST_TAB.index}>
            </TabPanel>
            <TabPanel value={HISTORY_TAB.index}>
              <History/>
            </TabPanel>
          </div>
        </TabContext>

        <ImportDialog open={openImport} setOpen={setOpenImport} defaultTab={'2'}/>
      </div>
  );
};

export default withStyles(styles)(Participants);
