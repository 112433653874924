import {useDispatch, useSelector} from "react-redux";
import {updateSystemFilesKeyClear, updateSystemFilesKeyStart} from "../features/systemFiles/updateSystemFiles/slices";
import {useEffect, useState} from "react";
import {deleteSystemFilesKeyClear, deleteSystemFilesKeyStart} from "../features/systemFiles/deleteSystemFiles/slices";
import useHandleToast from "./UseHandleToast";

const UseSystemFile = ({ onFileUpdated, onFileDeleted, overrideDeleteSystemFileKey = 'deleteSystemFilesKey', overrideUpdateSystemFileKey = 'updateSystemFilesKey' }) => {
    const dispatch = useDispatch();
    const systemFileDeleted = useSelector(state => state[overrideDeleteSystemFileKey]);
    const systemFilesUpdated = useSelector(state => state[overrideUpdateSystemFileKey]);
    const [listener, setListener] = useState(false)

    const clearListener = () => {
        setListener(false)
    }

    useHandleToast(systemFileDeleted, dispatch, deleteSystemFilesKeyClear,
        'spider.systemFile.delete.success',
        'spider.systemFile.delete.error', listener, clearListener)

    useHandleToast(systemFilesUpdated, dispatch, updateSystemFilesKeyClear,
        'spider.systemFile.update.success',
        'spider.systemFile.update.error', listener, clearListener)

    const deleteSystemFile = (imageData) => {
        setListener(true)
        dispatch(deleteSystemFilesKeyStart({ imageId: imageData.uuid }))
    }

    // manage only lone file for now
    const updateSystemFile = (fileData, code, teamGroupId) => {
        setListener(true)
        const file = new FormData();
        file.append('code', code);
        file.append('src', fileData);
        dispatch(updateSystemFilesKeyStart({ code, file, teamGroup: teamGroupId }))
    }

    useEffect(() => {
        if (!listener) return

        if (systemFilesUpdated.systemFileUpdated !== null) {
            onFileUpdated(systemFilesUpdated.systemFileUpdated)
        }
        if (systemFileDeleted.success) {
            onFileDeleted()
        }
    }, [systemFilesUpdated, systemFileDeleted, dispatch]);

    return { deleteSystemFile, updateSystemFile }
}

export default UseSystemFile;
