import React, {useState} from 'react';
import {ScreenBottomDialog} from "../ScreenBottomDialog";
import {DialogContent, Paper, Tab, Typography} from "@material-ui/core";
import {Button} from "../index";
import {CloseSquare} from "iconsax-react";
import {withStyles} from "@mui/styles";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {useIntl} from "react-intl";
import {ImportContent} from "./components/ImportContent";
import useParticipantsHelper from "../../hooks/Participants/UseParticipantsHelper";
import usePointHelper from "../../hooks/Points/UsePointHelper";
import {DialogIndicator} from "../DialogIndicator";
import {neutralColors} from "../../themes";
import {createTheme} from "@material-ui/core/styles";

const styles = {
    dialogTitle: {
        display: 'flex !important',
        flexDirection: 'column !important',
        padding: '1rem !important',
        width: '100%',
    },
    closeTitleDialog: {
        display: 'flex !important',
        width: '100%',
        flexDirection: 'row-reverse !important',
        padding: '1rem !important',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        gap: '1rem',
        backgroundColor: neutralColors.neutralBg,
        [createTheme().breakpoints.up('md')]: {
            padding: '0rem 2rem 2rem 2rem !important',
        },
    },
    dialogPaperContent: {
        marginRight: 'auto',
        marginLeft: 'auto',
        [createTheme().breakpoints.up('md')]: {
            padding: '1rem',
            width: '75%',
        },
    }
}

const ImportDialog = ({ open, setOpen, defaultTab, ...props }) => {
    const intl = useIntl();
    const { importRequirements: participantsImportRequirements, onDownloadImportPattern: participantOnDownloadImportPattern, validateImport: validateParticipantsImport } = useParticipantsHelper({ download: () => {} });
    const { importRequirements: pointsImportRequirements, onDownloadImportPattern: pointsOnDownloadImportPattern, validateImport: validatePointsImport } = usePointHelper({ download: () => {} });

    const POINTS_TAB = { label: intl.formatMessage({id: 'spider.points.title'}), index: '1' }
    const PARTICIPANTS_TAB = { label: intl.formatMessage({id: 'spider.participants.title'}), index: '2' }

    const [tabValue, setTabValue] = useState(defaultTab ? defaultTab : PARTICIPANTS_TAB.index);

    const handleChange = (event, tab) => {
        setTabValue(tab);
    };

    const onParticipantValidate = () => {
        validateParticipantsImport()
        onClose()
    }

    const onPointValidate = () => {
        validatePointsImport()
        onClose()
    }

    const onClose = () => {
        setOpen(false)
    };

    console.log('participantsImportRequirements', participantsImportRequirements)
    console.log('pointsImportRequirements', pointsImportRequirements)

    return (
        <ScreenBottomDialog open={open} onClose={onClose} direction={'right'}>
            <DialogContent className={props.classes.dialogContent}>
                <div className={props.classes.dialogTitle}>
                    <DialogIndicator/>
                    <div className={props.classes.closeTitleDialog}>
                        <Button variant={'contained'} color={'primary'} className={'size-tiny icon light'} disableRipple
                                onClick={onClose}>
                            <CloseSquare/>
                        </Button>
                    </div>
                    <div>
                        <Typography variant={'h1'} component={'h1'} className={'title underline-center'}>
                            {intl.formatMessage({id: 'spider.importDialog.title'})}
                        </Typography>
                    </div>
                </div>

                <Paper className={props.classes.dialogPaperContent}>
                    <TabContext value={tabValue}>
                        <TabList onChange={handleChange} disableRipple TabIndicatorProps={{children: <div/>}} className={'switch'}>
                            <Tab label={POINTS_TAB.label} value={POINTS_TAB.index} disableFocusRipple/>
                            <Tab label={PARTICIPANTS_TAB.label} value={PARTICIPANTS_TAB.index} disableFocusRipple/>
                        </TabList>
                        <TabPanel value={POINTS_TAB.index}>
                            <ImportContent
                                key={'imports'}
                                title={intl.formatMessage({id: 'spider.points.helpImport.header.title'})}
                                subtitle={intl.formatMessage({id: 'spider.points.helpImport.header.subtitle'})}
                                downloadPattern={pointsOnDownloadImportPattern} requirementObject={pointsImportRequirements}
                                validateImport={onPointValidate}
                            />
                        </TabPanel>
                        <TabPanel value={PARTICIPANTS_TAB.index}>
                            <ImportContent
                                key={'participants'}
                                title={intl.formatMessage({id: 'spider.participants.helpImport.header.title'})}
                                subtitle={intl.formatMessage({id: 'spider.participants.helpImport.header.subtitle'})}
                                downloadPattern={participantOnDownloadImportPattern} requirementObject={participantsImportRequirements}
                                validateImport={onParticipantValidate}
                            />
                        </TabPanel>
                    </TabContext>
                </Paper>
            </DialogContent>
        </ScreenBottomDialog>
    )
}

export default withStyles(styles)(ImportDialog);
