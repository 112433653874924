import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {UserRoles} from "../enums";
import {SystemFileStatuses} from "../enums/systemFileStatuses";
import {useAuth} from "../../auth";

const UseApprovalHelper = (systemFileCode) => {
    const { hierarchyNodeUser } = useAuth();
    const [documentToApprove, setDocumentToApprove] = useState(null);
    const [showMainContent, setShowMainContent] = useState(false)
    const [approveDocumentDialog, setApproveDocumentDialog] = useState(false);

    const mainState = useSelector(state => state.mainKey);

    useEffect(() => {
        if (hierarchyNodeUser.role !== UserRoles.COLLABORATOR) {
            setShowMainContent(true)
            return false
        }
        if (!mainState.success && (!mainState.legalDocuments || mainState.legalDocuments.length === 0)) {
            return false
        }
        const documentToApprove = mainState.legalDocuments.results
            .find((document) => document.code === systemFileCode && document.status === SystemFileStatuses.ACTIVE)
        if (!documentToApprove) {
            return false
        }

        if (documentToApprove._context && !documentToApprove._context.approved) {
            setShowMainContent(false)
            setDocumentToApprove(documentToApprove)
            setApproveDocumentDialog(true)
        } else {
            setShowMainContent(true)
        }
    }, [mainState]);

    const closeDocumentApproval = () => {
        setApproveDocumentDialog(false)
        setDocumentToApprove(null)
        setShowMainContent(true)
    }

    return { closeDocumentApproval, documentToApprove, showMainContent, approveDocumentDialog };
}

export default UseApprovalHelper;
