import instance from '../instance';

const baseUrl = 'system-files/';

const systemImages = {
  list() {
    return instance.get(baseUrl);
  },
  hierarchyNodeList(teamGroup = 1, codes = [], statuses = [], page, pageSize) {
    const params = new URLSearchParams();
    if (codes && codes.length > 0) {
      codes.forEach((code) => {
        params.append('codes', code);
      });
    }
    if (statuses && statuses.length > 0) {
      statuses.forEach((status) => {
        params.append('status', status);
      });
    }
    if (page) {
      params.append('page', page);
    }
    if (pageSize) {
      params.append('page_size', pageSize);
    }
    return instance.get(`hierarchy-nodes/${teamGroup}/${baseUrl}`, { params });
  },
  update(code, image) {
    const url = `${baseUrl}${code}/`;
    return instance.put(url, image);
  },
  hierarchyNodeUpdate(code, image, teamGroup = 1) {
    const url = `hierarchy-nodes/${teamGroup}/${baseUrl}`;

    return instance.post(url, image, {
      params: {
        code
      }
    });
  },
  delete(imageId) {
    const url = `${baseUrl}${imageId}`;
    return instance.delete(url);
  }
};

export default systemImages;
