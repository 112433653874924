import React, {useEffect, useState} from 'react';
import { useClearCache } from 'react-clear-cache';
import { ErrorBoundary } from 'react-error-boundary';
import {useHistory, useParams} from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { cssBaselineStyles } from './styles';
import { AppBar, Footer, SideBar } from './components';
import { ErrorFallback } from '../../../scenes';
import { useTheme } from '../../../../distributors';
import {PreventLeavingWithoutSavingDialog} from "../../PreventLeavingWithoutSavingDialog";
import UseHistoryBlock from "../../../hooks/UseHistoryBlock";
import {clearFailure, mainKeyStart} from "../../../features/main/slices";
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../../../auth";
import {SystemFileCodes} from "../../../enums";
import {DocumentApproval} from "../../DocumentApproval";
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import {useIntl} from "react-intl";
import useApprovalHelper from "../../../hooks/UseApprovalHelper";
import OperationDialog from "./components/OperationDialog";

const UserLayout = ({ component: Component, ...props }) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const history = useHistory();

    const mainState = useSelector(state => state.mainKey);
    const [config, setConfig] = useState(mainState.config);

    const theme = useTheme(cssBaselineStyles, config);

    const [refreshMain, setRefreshMain] = useState(false);

    const { emptyCacheStorage } = useClearCache();
    const { contract } = useParams();
    const { hierarchyNodeUser } = useAuth();
    const { openPreventLeavingWithoutSavingDialog, continueNavigation, submitModification } = UseHistoryBlock();
    const { closeDocumentApproval, documentToApprove, showMainContent, approveDocumentDialog } = useApprovalHelper(SystemFileCodes.REGLEMENT,
        () => { setRefreshMain(!refreshMain) });


    const isMobile = isWidthDown('xs', props.width);

    useEffect(() => {
        if (contract) {
            dispatch(mainKeyStart({ teamGroupId: contract }));
        }
    }, [contract, hierarchyNodeUser, refreshMain]);

    useEffect(() => {
        if (mainState.error) {
            dispatch(clearFailure());
            history.push('/nodes')
        }
        if (mainState.config) {
            setConfig(mainState.config)
        }
    }, [mainState]);

    const handleError = (error, info) => {
        console.error('Error caught by ErrorBoundary: ', error, info);
    };

    const handleReset = async () => {
        await emptyCacheStorage();
        history.push('/');
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className='sidebar-root'>
                    <AppBar/>
                    <div className='sidebar'>
                        {(!isMobile && showMainContent) && <div className={'sidebar-content'}>
                            <OperationDialog/>
                            <SideBar/>
                        </div>}

                        <div className='ft-wrapper'>
                            <main style={{ justifyContent: 'start' }}>
                                {(showMainContent || (!documentToApprove && !showMainContent)) && <ErrorBoundary
                                    FallbackComponent={ErrorFallback}
                                    onReset={handleReset}
                                    onError={handleError}
                                >
                                    <Component/>
                                </ErrorBoundary>}

                                <DocumentApproval open={approveDocumentDialog}
                                                  onDialogClose={closeDocumentApproval}
                                                  title={intl.formatMessage({id: 'spider.documentApproval.title'})}
                                                  checkBoxValidationLabel={intl.formatMessage({id: 'spider.documentApproval.acceptRulesLabel'})}
                                                  checkBoxValidationErrorLabel={intl.formatMessage({id: 'spider.documentApproval.errorLabel'})}
                                                  document={documentToApprove}
                                />

                                <PreventLeavingWithoutSavingDialog open={openPreventLeavingWithoutSavingDialog}
                                                                   onDialogClose={continueNavigation}
                                                                   leaveWithoutRegister={continueNavigation}
                                                                   submitModifications={submitModification}
                                />
                            </main>
                            <Footer />
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </>
    );
};

export default withWidth()(UserLayout);
