import React, { useEffect, useRef, useState } from 'react';
import { DefaultText, EmptyState, WrapperWidget } from '../../../components';
import { Grid, withStyles, makeStyles } from '@material-ui/core';

const style = (theme) => {
  return {
    emptyState: {
      width: '70%',
      margin: 'auto',
    },
  };
};

const ToucanWidget = ({ classes }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      console.log('Container exists:', container);
      const script = document.createElement('script');
      script.src =
        'https://objow.toucantoco.com/scripts/embedLauncher.js?id=0253acbe-c25a-489a-8c32-cb6c3b3a99fd&token=_0XBPWQQ_2e1f720c-37fd-4b06-9fd0-630ac0082bae';
      script.async = true;
      script.type = 'text/javascript';
      console.log('Script created:', script);

      container.appendChild(script);
      console.log('Script appended to container');
    }
  }, [containerRef.current]);

  return (
    <WrapperWidget title='Toucan'>
      <div style={{ height: 300 }}>
        <Grid
          container
          style={{
            padding: 5,
            width: '100%',
            height: '100%',
          }}
          alignItems='center'
        >
          <Grid item xs>
            <div
              ref={containerRef}
              id='toucan-container'
              style={{ height: '100%', width: '100%' }}
            ></div>
          </Grid>
        </Grid>
      </div>
    </WrapperWidget>
  );
};
export default withStyles(style)(ToucanWidget);
