import React from 'react';
import usePointHelper from "../../../../hooks/Points/UsePointHelper";
import {PointStatuses} from "../../../../enums";
import {Button} from "../../../../components";
import {useIntl} from "react-intl";
import {RowsPerPageSelector} from "../../../../components/RowsPerPageSelector";
import {Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import {systemColors} from "../../../../themes";
import {DocumentDownload} from "iconsax-react";
import usePagination from "../../../../hooks/UsePagination";

const History = () => {
    const intl = useIntl();
    const data = [
        {
            clientName: 'BoostPOWER2023',
            name: 'Christophe Marchand',
            date: '21.07.2023',
            lines: 123,
            linesError: 12,
            status: PointStatuses.ERROR,
        },
        {
            clientName: 'BoostPOWER2023',
            name: 'Christophe Marchand',
            date: '21.07.2023',
            lines: 123,
            linesError: 12,
            status: PointStatuses.IMPORTED,
        },
        {
            clientName: 'BoostPOWER2023',
            name: 'Christophe Marchand',
            date: '21.07.2023',
            lines: 123,
            linesError: 12,
            status: PointStatuses.RUNNING,
        },
        {
            clientName: 'BoostPOWER2023',
            name: 'Christophe Marchand',
            date: '21.07.2023',
            lines: 123,
            linesError: 12,
            status: PointStatuses.PARTIAL,
        },
        {
            clientName: 'BoostPOWER2023',
            name: 'Christophe Marchand',
            date: '21.07.2023',
            lines: 123,
            linesError: 12,
            status: PointStatuses.RUNNING,
        },
    ];

    const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, paginationCount } = usePagination({ dataLength: data.length });

    const download = (row) => {}

    const columns = [
        { field: 'date', label: intl.formatMessage({id: 'spider.points.columns.date'}) },
        { field: 'name', label: intl.formatMessage({id: 'spider.points.columns.name'}) },
        { field: 'lines', label: intl.formatMessage({id: 'spider.points.columns.lines'}) },
        { field: 'status', label: intl.formatMessage({id: 'spider.points.columns.status'}) },
        { field: 'action', label: intl.formatMessage({id: 'spider.points.columns.action'}) },
    ];

    const { getChipsLabel, getChipsStatus } = usePointHelper();

    return (
        <Paper>
            <RowsPerPageSelector rowsPerPage={rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage}/>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            { columns.map((column) => {
                                return (<TableCell>{column.label}</TableCell>)
                            }) }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.length > 0 ? (
                            data.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        <TableCell>
                                            <div>
                                                {row.date}
                                                <br/>
                                                <div style={{fontSize: '10px'}}>{'10.30'}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                {row.clientName}
                                                <br/>
                                                <div style={{fontSize: '10px'}}>{row.name}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                {row.lines}
                                                <br/>
                                                {row.linesError > 0 ? <div style={{
                                                    fontSize: '10px',
                                                    color: systemColors.errorDark
                                                }}>{`dont ${row.linesError} en erreur`}</div> : ''}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <Chip label={getChipsLabel(row.status)} className={getChipsStatus(row.status)}/>
                                        </TableCell>
                                        <TableCell>
                                            <Button variant={'contained'} color={'primary'} className={'size-tiny icon'} disableRipple onClick={download(row)}>
                                                <DocumentDownload size={24}/>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} align='center'>
                                {intl.formatMessage({
                                        id: 'spider.data_table.no_data_available',
                                    })}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={paginationCount}
                page={page}
                onChange={handleChangePage}
                shape='rounded'
            />
        </Paper>
    )
}

export default History;
