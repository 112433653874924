import {ParticipantsStatuses} from "../../enums";
import {useIntl} from "react-intl";

const UseParticipantsHelper = ({ download = () => {} }) => {
    const intl = useIntl();

    const onDownloadImportPattern = () => {
    }

    const importRequirements = {
        helpInfo: {
            helpInfo: [
                intl.formatMessage({id: 'spider.participants.helpImport.helpInfo.waitedFormat'}),
                intl.formatMessage({id: 'spider.participants.helpImport.helpInfo.orderFormat'}),
                intl.formatMessage({id: 'spider.participants.helpImport.helpInfo.optionalInfo'})
            ],

            requirement: [
                {
                    title: intl.formatMessage({id: 'spider.participants.helpImport.requirements.number.title'}),
                    description: intl.formatMessage({id: 'spider.participants.helpImport.requirements.number.description'}),
                    required: true
                },
                {
                    title: intl.formatMessage({id: 'spider.participants.helpImport.requirements.lastName.title'}),
                    description: intl.formatMessage({id: 'spider.participants.helpImport.requirements.lastName.description'}),
                    required: true,
                    category: null
                },
                {
                    title: intl.formatMessage({id: 'spider.participants.helpImport.requirements.name.title'}),
                    description: intl.formatMessage({id: 'spider.participants.helpImport.requirements.name.description'}),
                    required: true,
                    category: null
                },
                {
                    title: intl.formatMessage({id: 'spider.participants.helpImport.requirements.email.title'}),
                    description: intl.formatMessage({id: 'spider.participants.helpImport.requirements.email.description'}),
                    required: true,
                    category: null
                },
                {
                    title: intl.formatMessage({id: 'spider.participants.helpImport.requirements.senior.title'}),
                    description: intl.formatMessage({id: 'spider.participants.helpImport.requirements.senior.description'}),
                    required: false,
                    category: null
                },
                {
                    title: intl.formatMessage({id: 'spider.participants.helpImport.requirements.inscriptionStatus.title'}),
                    description: intl.formatMessage({id: 'spider.participants.helpImport.requirements.inscriptionStatus.description'}),
                    required: true,
                    category: null
                },
                {
                    title: intl.formatMessage({id: 'spider.participants.helpImport.requirements.socialRaison.title'}),
                    description: intl.formatMessage({id: 'spider.participants.helpImport.requirements.socialRaison.description'}),
                    required: true,
                    category: intl.formatMessage({id: 'spider.participants.helpImport.category.moralePerson'})
                },
                {
                    title: intl.formatMessage({id: 'spider.participants.helpImport.requirements.primaryAddress.title'}),
                    description: intl.formatMessage({id: 'spider.participants.helpImport.requirements.primaryAddress.description'}),
                    required: true,
                    category: intl.formatMessage({id: 'spider.participants.helpImport.category.moralePerson'})
                },
                {
                    title: intl.formatMessage({id: 'spider.participants.helpImport.requirements.secondaryAddress.title'}),
                    description: intl.formatMessage({id: 'spider.participants.helpImport.requirements.secondaryAddress.description'}),
                    required: false,
                    category: intl.formatMessage({id: 'spider.participants.helpImport.category.moralePerson'})
                },
                {
                    title: intl.formatMessage({id: 'spider.participants.helpImport.requirements.postalCode.title'}),
                    description: intl.formatMessage({id: 'spider.participants.helpImport.requirements.postalCode.description'}),
                    required: true,
                    category: intl.formatMessage({id: 'spider.participants.helpImport.category.moralePerson'})
                },
                {
                    title: intl.formatMessage({id: 'spider.participants.helpImport.requirements.city.title'}),
                    description: intl.formatMessage({id: 'spider.participants.helpImport.requirements.city.description'}),
                    required: true,
                    category: intl.formatMessage({id: 'spider.participants.helpImport.category.moralePerson'})
                }
            ]
        }
    }

    const validateImport = () => {
    }

    const getChipsLabel = (status) => {
        let chipStatus
        switch (status) {
            case ParticipantsStatuses.ERROR:
                chipStatus = intl.formatMessage({id: 'spider.points.status.error'})
                break
            case ParticipantsStatuses.IMPORTED:
                chipStatus = intl.formatMessage({id: 'spider.points.status.imported'})
                break
            case ParticipantsStatuses.PARTIAL:
                chipStatus = intl.formatMessage({id: 'spider.points.status.partial'})
                break
            default:
                chipStatus = intl.formatMessage({id: 'spider.points.status.running'})
                break
        }

        return chipStatus
    }

    const getChipsStatus = (status) => {
        let chipStatus
        switch (status) {
            case ParticipantsStatuses.ERROR:
                chipStatus = 'error'
                break
            case ParticipantsStatuses.IMPORTED:
                chipStatus = 'imported'
                break
            case ParticipantsStatuses.PARTIAL:
                chipStatus = 'partial'
                break
            default:
                chipStatus = 'inactive'
                break
        }

        return chipStatus
    }

    return { getChipsStatus, getChipsLabel, importRequirements, onDownloadImportPattern, validateImport }
}

export default UseParticipantsHelper;
