import {neutralColors, themeColors} from '../colors';

const buttonStyles = {
    MuiTabs: {
        root: {
            background: `linear-gradient(to bottom, transparent calc(100% - 2px), ${neutralColors.neutral300} 2px)`,
            padding: '8px 24px 0px 24px',
            '&.switch': {
                background: 'none',
            },
            '&.switch > .MuiTabs-scroller > .MuiTabs-indicator': {
                display: 'none',
            },
            '&.switch > .MuiTabs-scroller > .MuiTabs-flexContainer': {
                justifyContent: 'center',
            },
            '&.switch > .MuiTabs-scroller > .MuiTabs-flexContainer > button': {
                backgroundColor: neutralColors.neutral100,
                '& > .MuiTab-wrapper': {
                    padding: '8px 16px 8px 16px',
                    color: themeColors.primaryRegular,
                    fontWeight: 700
                }
            },
            '&.switch > .MuiTabs-scroller > .MuiTabs-flexContainer > button:first-child': {
                borderTopLeftRadius: '92px',
                borderBottomLeftRadius: '92px'
            },
            '&.switch > .MuiTabs-scroller > .MuiTabs-flexContainer > button:last-child': {
                borderTopRightRadius: '92px',
                borderBottomRightRadius: '92px'
            },
            '&.switch > .MuiTabs-scroller > .MuiTabs-flexContainer > .Mui-selected > .MuiTab-wrapper': {
                backgroundColor: themeColors.secondaryRegular,
                borderRadius: '24px',
                color: themeColors.primaryContrastText
            }
        },
        indicator: {
            backgroundColor: themeColors.secondaryRegular,
        },
    },
    MuiTab: {
        root: {
            color: neutralColors.neutral900,
            textAlign: 'center',
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            textTransform: 'none',
            '&.Mui-selected': {
                color: themeColors.secondaryRegular
            }
        },
        textColorInherit: {
            color: neutralColors.neutral900,
        }
    },
    MuiTabPanel: {
        root: {
            padding: '32px 24px 24px 24px',
        }
    }
};

export default buttonStyles;
