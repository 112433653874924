import React from 'react';
import {
  AppBarSubTitle,
  Loader,
  MainLayoutComponent,
} from '../../../../components';
import { injectIntl } from 'react-intl';
import { EmbedWidget } from './components';

class AdminToucan extends MainLayoutComponent {
  state = {
    embedUrl: '',
    currentUrl: '',
  };
  componentDidMount() {
    const { intl } = this.props;
    this.props.handleTitle(intl.formatMessage({ id: 'admin.title' }));
    this.props.handleSubHeader(
      <AppBarSubTitle title={intl.formatMessage({ id: 'admin.toucan' })} />
    );
    this.props.handleMaxWidth('md');
    this.props.activateReturn();
  }

  renderLoader() {
    return <Loader centered />;
  }

  render() {
    return (
      <>
        <EmbedWidget />
      </>
    );
  }
}

export default injectIntl(AdminToucan);
