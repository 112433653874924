import {PointStatuses} from "../../enums";
import {useIntl} from "react-intl";

const UsePointHelper = () => {
    const intl = useIntl();

    const onDownloadImportPattern = () => {
    }

    const importRequirements = {
        helpInfo: {
            helpInfo: [
                intl.formatMessage({id: 'spider.points.helpImport.helpInfo.waitedFormat'}),
                intl.formatMessage({id: 'spider.points.helpImport.helpInfo.orderFormat'}),
                intl.formatMessage({id: 'spider.points.helpImport.helpInfo.optionalInfo'})
            ],

            requirement: [
                {
                    title: intl.formatMessage({id: 'spider.points.helpImport.requirements.number.title'}),
                    description: intl.formatMessage({id: 'spider.points.helpImport.requirements.number.description'}),
                    required: true
                },
                {
                    title: intl.formatMessage({id: 'spider.points.helpImport.requirements.points.title'}),
                    description: intl.formatMessage({id: 'spider.points.helpImport.requirements.points.description'}),
                    required: true,
                    category: null
                },
                {
                    title: intl.formatMessage({id: 'spider.points.helpImport.requirements.comment.title'}),
                    description: intl.formatMessage({id: 'spider.points.helpImport.requirements.comment.description'}),
                    required: false,
                    category: null
                }
            ]
        }
    }

    const validateImport = () => {
    }

    const getChipsLabel = (status) => {
        let chipStatus
        switch (status) {
            case PointStatuses.ERROR:
                chipStatus = intl.formatMessage({id: 'spider.points.status.error'})
                break
            case PointStatuses.IMPORTED:
                chipStatus = intl.formatMessage({id: 'spider.points.status.imported'})
                break
            case PointStatuses.PARTIAL:
                chipStatus = intl.formatMessage({id: 'spider.points.status.partial'})
                break
            default:
                chipStatus = intl.formatMessage({id: 'spider.points.status.running'})
                break
        }

        return chipStatus
    }

    const getChipsStatus = (status) => {
        let chipStatus
        switch (status) {
            case PointStatuses.ERROR:
                chipStatus = 'error'
                break
            case PointStatuses.IMPORTED:
                chipStatus = 'imported'
                break
            case PointStatuses.PARTIAL:
                chipStatus = 'partial'
                break
            default:
                chipStatus = 'inactive'
                break
        }

        return chipStatus
    }

    return { getChipsStatus, getChipsLabel, importRequirements, onDownloadImportPattern, validateImport }
}

export default UsePointHelper;
